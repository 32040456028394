"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var config = {
    api: {
        foodOpsApiBaseUrl: 'https://8lhvy03ed6.execute-api.us-east-1.amazonaws.com/qa/foodops/graphql',
        commonApi: 'https://8lhvy03ed6.execute-api.us-east-1.amazonaws.com/qa/common',
        apiKey: 'xablauapikeyxablau123-qa',
        webSocketBaseUrl: 'wss://8048f3qds3.execute-api.us-east-1.amazonaws.com/qa'
    },
    cloudfront: 'dvd0z52bc4zch.cloudfront.net',
    cognito: {
        foodOpsUserPoolId: 'us-east-1_Mz2tbdqds',
        foodOpsUserPoolClientId: '6g1pdhutabd1uj8okm5ik3nke5'
    },
    pinpoint: '368689c047e24a748fb303020e63698a',
    region: 'us-east-1',
    secretsManager: {
        mapsApiKey: 'AIzaSyDLP8REBjQ8Goq1vIyX522we4aMnByRU7k'
    }
};
exports.default = config;
